<template>
    <div class="header">
        <warning-sample></warning-sample>
        <div class="logo-panel">
            <div class="logo-panel-left"></div>
            <div class="logo-panel-middle">
                <div class="logo">
                    <router-link :to="{path: '/main', query: {t: new Date().getTime()}}" tag="div" class="logo-img">
                        <img src="../assets/images/logo/ptn/logo01.png" alt="">
                    </router-link>
                    <div class="pc-userinfo" v-if="$store.state.isLogin">
                        <div class="minfo" @click="initUserInfo">
                            <img :src="$store.state.userInfo.rank.imageUrl" alt="" class="mimg">
                            <span class="mspan">{{$store.state.userInfo.nickname}}</span>
                        </div>
                        <div class="minfo" @click="initUserInfo">
                            <img src="../assets/images/icon/cenha/icon-top-money.png" alt="" class="mimg">
                            <span class="mspan">보유머니: {{$store.state.userInfo.cash|comma}}</span>
                        </div>
                        <div class="minfo" @click="changePoint">
                            <img src="../assets/images/icon/cenha/icon-top-point.png" alt="" class="mimg">
                            <span class="mspan">포인트: {{$store.state.userInfo.userPoint|comma}}</span>
                        </div>
                        <div class="minfo" @click="refreshCasinoCash">
                            <img src="../assets/images/icon/cenha/icon-top-casino.png" alt="" class="mimg">
                            <span class="mspan">카지노칩: {{$store.state.userInfo.casinocash|comma}}</span>
                        </div>
                    </div>
                    <div class="pc-smallbuttons">
                        <router-link tag="div" class="minfobutton"
                                     :to="{path: '/recharge', query: {t: new Date().getTime()}}">
                            <img src="../assets/images/icon/topbar/입금.png" alt="" style="height: 20px;vertical-align: center"><span style="margin-left: 3px">충전</span>
                        </router-link>
                        <router-link tag="div" class="minfobutton"
                                     :to="{path: '/exchange', query: {t: new Date().getTime()}}">
                            <img src="../assets/images/icon/topbar/출금.png" alt="" style="height: 20px;vertical-align: center"> <span style="margin-left: 3px">환전</span>
                        </router-link>
                        <router-link tag="div" class="minfobutton"
                                     :to="{path: '/customer_center', query: {t: new Date().getTime()}}">
                            <img src="../assets/images/icon/topbar/문의.png" alt="" style="height: 20px;vertical-align: center"> <span style="margin-left: 3px">문의</span>
                        </router-link>
                        <router-link tag="div" class="minfobutton"
                                     :to="{path: '/message', query: {t: new Date().getTime()}}">
                            <img src="../assets/images/icon/topbar/쪽지.png" alt="" style="height: 20px;vertical-align: center"> <span style="margin-left: 3px">쪽지</span>
                        </router-link>
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/culcheck', query: {t: new Date().getTime()}}">-->
<!--                            <img src="../assets/images/icon/topbar/출석.png" alt="" style="height: 20px;vertical-align: center"> <span style="margin-left: 3px">출석</span>-->
<!--                        </router-link>-->
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/coupon', query: {t: new Date().getTime()}}">-->
<!--                            <img src="../assets/images/icon/topbar/쿠폰.png" alt="" style="height: 20px;vertical-align: center"> <span style="margin-left: 3px">쿠폰</span>-->
<!--                        </router-link>-->
<!--                        <router-link tag="div" class="minfobutton"-->
<!--                                     :to="{path: '/recmember', query: {t: new Date().getTime()}}">-->
<!--                            <img src="../assets/images/icon/topbar/추천인.png" alt="" style="height: 16px;vertical-align: center"> <span style="margin-left: 3px">추천인</span>-->
<!--                        </router-link>-->
                        <!--                        <router-link tag="div" class="minfobutton"-->
                        <!--                                     :to="{path: '/user_info', query: {t: new Date().getTime()}}">회원정보-->
                        <!--                        </router-link>-->
                        <router-link tag="div" class="minfobutton logout" to="" @click.native="logout" v-if="$store.state.isLogin">로그아웃</router-link>
                    </div>
                    <div class="apply-button">
<!--                        <router-link tag="button" to="/recharge">충전</router-link>-->
<!--                        <router-link tag="button" to="/exchange">환전</router-link>-->
                    </div>
                    <div class="mobile-user-menu" @click="rightBarHandle">
                        <img src="../assets/images/menu/mobile-menu-w.png" alt="">
                    </div>
                </div>
                <div class="menu">
                    <router-link tag="div" to="/sports">스포츠</router-link>
                    <router-link tag="div" to="/sports_special">스페셜</router-link>
                    <router-link tag="div" to="/sports_inplay">LIVE스포츠</router-link>
                    <router-link tag="div" :to="{path: '/leisure/bet365_superleague', query: {t: new Date().getTime()}}" >가상스포츠</router-link>
                    <router-link tag="div" :to="{path: '/casino', query: {t: new Date().getTime()}}" >카지노&슬롯</router-link>
                    <router-link tag="div" :to="{path: '/leisure/eospowerball5m', query: {t: new Date().getTime()}}" >미니게임</router-link>
<!--                    <router-link tag="div" :to="{path: '/tgame365/tgame365highlow', query: {t: new Date().getTime()}}">토큰게임</router-link>-->
                    <router-link tag="div" to="/sports_betinfo">베팅내역</router-link>
                    <router-link tag="div" to="/notice">공지사항</router-link>
<!--                    <router-link tag="div" to="/sports_result">경기결과</router-link>-->
                    <router-link tag="div" to="/event">이벤트</router-link>
<!--                    <router-link tag="div" to="/customer_center">고객센터</router-link>-->
                    <!--                  -->
                </div>
                <div class="mobile-menu"  v-if="$store.state.isLogin">
                    <div class="mm01">
                        <div style="width: 140px">
                            <img :src="$store.state.userInfo.rank.imageUrl">
                            <span style="color: floralwhite;margin-left: 5px">{{$store.state.userInfo.nickname}}</span>
                        </div>
                    </div>
                    <div class="mm02">
                        <div>
                            머니: <span>{{$store.state.userInfo.cash|comma}}</span>
                        </div>
                        <div  @click="changePoint">
                            포인트: <span>{{$store.state.userInfo.userPoint|comma}}</span>
                        </div>
                    </div>
                    <div class="mm02">
                        <div @click="refreshCasinoCash">
                            카지노칩: <span>{{$store.state.userInfo.casinocash|comma}}</span>
                        </div>
                        <div>
                            슬롯칩: <span>{{$store.state.userInfo.slotcash|comma}}</span>
                        </div>

                    </div>
                </div>
                <div class="mqwarpper">
                    <img src="../assets/images/icon/topbar/한줄공지.png" alt="">
                    <marqueen-comp></marqueen-comp>
                </div>
                <div class="mobile-notlogin-button"  v-if="!$store.state.isLogin">
                    <button class="btn_login login" @click="showLoginPanel"><i class="fa fa-sign-in"></i> 로그인</button>
                    <button class="btn_register register" @click="showRegisterPanel"><i class="fa fa-user-circle"></i> 회원가입</button>
                </div>
            </div>
            <div class="logo-panel-right"></div>
        </div>

        <!--모바일 메뉴-->
        <!--        <mobile-top-menu></mobile-top-menu>-->
    </div>
</template>

<script>
    import sportsConst from "../common/sportsConst";
    import leisureConst from "../common/leisureConst";
    import MobileTopMenu from "./mobile/MobileTopMenu";
    import MobileTopUserInfo from "./mobile/MobileTopUserInfo";
    import {changePoint, getLineMessage, getSiteStatus, logout} from "../network/userRequest";
    import {INIT_USERINFO, RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import WarningSample from "./WarningSample";
    import MarqueenComp from "./MarqueenComp";
    import {getAccountBalance} from "../network/casinoOracleRequest";

    export default {
        name: "TopbarComp",
        components: {MarqueenComp, WarningSample, MobileTopUserInfo, MobileTopMenu},
        data() {
            return {
                leisureConst,
                cross: sportsConst.GAME_DISPLAYPOSITION_1x2,
                handicap: sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER,
                special: sportsConst.GAME_DISPLAYPOSITION_SPECIAL,
                live: sportsConst.GAME_DISPLAYPOSITION_LIVE,
                lineMessage: '',
            }
        },
        methods: {
            rightBarHandle() {
                this.$bus.$emit('rightBarHandle', !this.$store.state.mobileRightMenuStatus)
                this.$store.state.mobileRightMenuStatus = !this.$store.state.mobileRightMenuStatus
            },
            showLoginPanel() {
                if (!this.$store.state.isLogin && !this.$store.state.isShowLoginPanel) {
                    this.$store.state.isShowLoginPanel = true
                    this.$store.state.isShowRegisterPanel = false
                }
            },
            showRegisterPanel() {
                // if (!this.$store.state.isLogin) {
                //     this.$store.state.isShowRegisterPanel = true
                //     this.$store.state.isShowLoginPanel = false
                // }
                this.$store.state.isShowRegisterPanel = false
                this.$store.state.isShowLoginPanel = false
                this.$router.push('/agentcode')
            },
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                //SportsLinksComp.vue ->on
                this.$bus.$emit('sportsDisplayChanged', disp);
            },
            showMobileTopMenu() {
                //모바일 메뉴아이콘 클릭시
                //MobileTopMenu.vue에서 $on
                this.$bus.$emit('mobileTopMenuActive')
                //this.$bus.$emit('leftBarHandle', true)
            },
            showMobileUserInfo() {
                //모바일 유저아이콘 클릭시
                //MobileTopUserInfo.vue에서 $on
                this.$bus.$emit('mobileTopUserInfoActive')
            },
            logout() {
                this.$swal({
                    title: '로그아웃 하시겠습니까 ?',
                    type: 'question',
                    confirmButtonText: '로그아웃',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(INIT_USERINFO);
                        window.sessionStorage.clear();
                        this.$router.replace("/main")
                        this.$store.state.uncheckedMessageCount = 0;
                        logout();
                    }
                })

            },
            toMain() {
                this.$router.replace("/main")
            },
            getLineMessage() {
                getLineMessage().then(res => {
                    if (res.data.success) {
                        let lines = res.data.data;
                        lines.map(line => {
                            this.lineMessage = this.lineMessage + '   ' + line.content
                        })
                    }

                })
            },
            initUserInfo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                this.$store.dispatch('actionUserInfo')
                setTimeout(() => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                }, 1000)
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            confirmButtonText: '확인',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
            refreshCasinoCash() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getAccountBalance().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            },
            browser() {
                let u = navigator.userAgent
                let app = navigator.appVersion
                return {         //移动终端浏览器版本信息
                    trident: u.indexOf('Trident') > -1, //IE
                    presto: u.indexOf('Presto') > -1, //opera
                    webKit: u.indexOf('AppleWebKit') > -1, //Apple,Google
                    gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //FireFox
                    mobile: !!u.match(/AppleWebKit.*Mobile.*/), //
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //
                    android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //
                    iPhone: u.indexOf('iPhone') > -1, //
                    iPad: u.indexOf('iPad') > -1, //
                    webApp: u.indexOf('Safari') == -1//
                }
            }
        },
        created() {
            getSiteStatus().then(res => {
                if (!res.data.success) {
                    logout()
                    this.$router.replace("/main")
                    this.$store.state.isMaintaining = true;
                    this.$store.state.maintainingMessage = res.data.msg
                    this.$store.state.isLogin = false;
                    this.$store.state.isShowLoginPanel = true;
                } else {
                    this.$store.state.isMaintaining = false;
                    this.$store.state.maintainingMessage = ''
                }
            })
            if (this.$store.state.isLogin == false) {
                if(this.$route.path.indexOf('main') == -1){
                    this.$store.state.isShowLoginPanel = true;
                }
                this.$router.replace("/main")
            }

            this.$store.state.ismobile = this.browser().mobile
            //랭킹,보너스,베팅금액,당첨금액 등 설정요청
            this.$store.dispatch('actionCommonConfig')
            this.getLineMessage();
            if (this.$store.state.isLogin) {
                this.$store.state.uncheckedMessageCount = 0;
                this.$store.dispatch('actionUserInfo')
                this.$store.dispatch('actionUnCheckedMessageCount')
                setTimeout(() => {
                    getAccountBalance().then(res => {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    })
                }, 500)
            } else {
                this.$store.commit(INIT_USERINFO);
            }

        },
    }
</script>

<style scoped>
    .header {
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        margin-top: 0;
        z-index: 1001;
        background-color: var(--headerBg);
        color: #dedede;
    }

    .header .small-menu {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        background: linear-gradient(180deg, #020202, #393939);
    }

    .header .small-menu .sm-left {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding-left: 20px;
    }

    .header .small-menu .sm-left div {
        width: 120px;
        cursor: pointer;
    }

    .header .small-menu .sm-left img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
    }

    .header .small-menu .sm-right {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .header .small-menu .sm-right .sm-right-button {
        height: 28px;
        line-height: 28px;
        color: #bebebe;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
    }
    .header .small-menu .sm-right .sm-right-button:hover {
        color: #fff60d;
        transition: .3s;
    }

    .header .small-menu .sm-right .dota {
        width: 1px;
        height: 10px;
        color: #c9c9c9;
        background-color: #787878;
        margin: 0 20px;
    }

    .header .logo-panel{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
    }
    .header .logo-panel .logo-panel-left{
        width: 0px;
    }
    .header .logo-panel .logo-panel-right{
        width: 0px;
    }
    .header .logo-panel .logo-panel-middle{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
    }
    .header .logo-panel .logo-panel-middle .logo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding: 30px 10px 0 10px;
    }
    .header .logo-panel .logo-panel-middle .logo .logo-img{
        width: 15%;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
    }
    .header .logo-panel .logo-panel-middle .logo .logo-img img{
        height: 70px;
    }

    .header .logo-panel .logo-panel-middle .logo .pc-userinfo{
        width: 40%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .header .logo-panel .logo-panel-middle .logo .pc-userinfo .minfo {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 5px;
        cursor: pointer;
    }

    .header .logo-panel .logo-panel-middle .logo .pc-userinfo .mimg {
        height: 24px;
    }

    .header .logo-panel .logo-panel-middle .logo .pc-smallbuttons{
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        padding-right: 10px;
    }
    .header .logo-panel .logo-panel-middle .logo .pc-smallbuttons .minfobutton {
        width: 70px;
        height: 24px;
        line-height: 24px;
        padding: 0 3px;
        border-radius: 3px;
        --background-color: #403c31;
        --border: 1px solid #403c31;
        margin: 0 3px;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff9ff;
    }
    .header .logo-panel .logo-panel-middle .logo .pc-smallbuttons .minfobutton:hover {
        color: #fff60d;
        transition: .7s ;
    }
    .header .logo-panel .logo-panel-middle .logo .pc-smallbuttons  .logout{
        border: 2px solid #063f76;
        border-radius: 20px;

    }


    .header .logo-panel .logo-panel-middle .logo .apply-button{
        width: 33%;
        justify-content: center;
        align-items: center;
        display: none;
    }
    .header .logo-panel .logo-panel-middle .logo .apply-button button{
        height: 35px;
        background: linear-gradient(180deg, #111111, #343434);
        border: 1px solid #544c37;
        border-radius: 1px;
        margin: 0 5px;
        font-size: 14px;
        font-weight: bold;
        width: 50%;
    }
    .header .logo-panel .logo-panel-middle .logo .mobile-user-menu{
        width: 33%;
        display: none;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;
    }
    .header .logo-panel .logo-panel-middle .logo .mobile-user-menu img{
        height: 40px;
    }
    .header .logo-panel .logo-panel-middle .menu{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        border-radius: 5px;
        border-top: 1px solid #1f92ff;
        margin-top: 5px;
        background: linear-gradient(180deg, var(--menu2bg1), var(--menu2bg2));
        box-sizing: border-box;
        padding-left: 320px;
        padding-right: 320px;


    }
    .header .logo-panel .logo-panel-middle .menu div{
        color: #dcdcdc;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        padding: 15px 20px;
    }
    .header .logo-panel .logo-panel-middle .menu div:hover{
        color: #1f92ff;
        transition: .3s;
    }
    .header .logo-panel .logo-panel-middle .mobile-menu{
        display: none;
    }
    .header .logo-panel .logo-panel-middle .mqwarpper{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding-left: 335px;
        padding-right: 335px;
        --background-color: #fff60d;
    }
    .mqwarpper img{
        margin-right: 5px;
        margin-top: 5px;
        height: 25px;
    }
    .header .logo-panel .logo-panel-middle .mobile-notlogin-button{
        display: none;
    }


    @media screen and (max-width: 1024px) {
        .header{
            height: 140px!important;
        }
        .header .small-menu {
            display: none;
        }
        .header .logo-panel {
            --border-bottom: 1px solid #4a4a4c;
            height: 180px!important;
            padding: 5px 2px!important;
        }
        .header .logo-panel .logo-panel-left {
            display: none;
        }
        .header .logo-panel .logo-panel-middle {
            width: 100%;
            padding: 0!important;
            display: flex;
            flex-direction: column;
        }
        .header .logo-panel .logo-panel-right {
            display: none;
        }
        .header .logo-panel .logo-panel-middle .menu {
            display: none;
        }
        .header .logo-panel .logo-panel-middle .logo{
            margin-bottom: 10px;
            padding: 5px 2px!important;
        }
        .header .logo-panel .logo-panel-middle .logo .logo-img{
            width: 40%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .header .logo-panel .logo-panel-middle .logo .logo-img img{
            width: 100%;
        }
        .header .logo-panel .logo-panel-middle .logo .apply-button{
            width: 50%;
            height: 45px!important;
            display: flex!important;
            justify-content: center;
            align-items: center;
        }
        .header .logo-panel .logo-panel-middle .logo .mobile-user-menu{
            width: 10%;
            height: 45px;
            display: flex!important;
            justify-content: flex-end;
            align-items: center;
            padding: 3px;

        }
        .header .logo-panel .logo-panel-middle .logo .logo-img img{
            height: 45px;
        }
        .header .logo-panel .logo-panel-middle .logo .mobile-user-menu img {
            height:30px!important;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu{
            background: linear-gradient(180deg, #2e323e, #060709);
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm01{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            width: 32%;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm01 img{
            width: 30px;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            color: #fff9ff;
            width: 34%;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02 div{
            width: 140px;
            box-sizing: border-box;
            padding: 5px ;
        }
        .header .logo-panel .logo-panel-middle .mobile-menu .mm02 div span{
            color: #f9c36b;
        }

        .header .logo-panel .logo-panel-middle .mobile-notlogin-button{
            --background: linear-gradient(180deg, #3f3f3f, #020202);
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            margin-top: 10px;
        }
        .header .logo-panel .logo-panel-middle .mobile-notlogin-button button{
            width: 49.5%;
            height: 38px;
            line-height: 38px;
        }
        .header .logo-panel .logo-panel-middle .mobile-notlogin-button .login{
            background: linear-gradient(180deg, var(--loginBtnBg), var(--loginBtnBg));
            font-weight: bold;
            color: #fff9ff;

        }
        .header .logo-panel .logo-panel-middle .mobile-notlogin-button .register{
            background: linear-gradient(180deg, var(--regBtnBg), var(--regBtnBg));
            font-weight: bold;
            color: #353535;
        }

        .header .logo-panel .logo-panel-middle .logo .pc-smallbuttons{
            display: none!important;
        }

        .header .logo-panel .logo-panel-middle .logo .pc-userinfo{
            display: none!important;
        }
        .header .logo-panel .logo-panel-middle .mqwarpper{
            padding: 0 5px!important;
        }
        .mqwarpper img{
            margin-left: 1px!important;
        }

    }
</style>

